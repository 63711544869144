<mat-card>
    <mat-card-content>
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
            <h2>Registrieren</h2>
            <mat-error *ngIf="registrationError">
                {{errorMessage}}
            </mat-error>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Vorname" formControlName="firstname" required>
                <mat-error>
                    Bitte gib deinen Vornamen ein
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Nachname" formControlName="lastname" required>
                <mat-error>
                    Bitte gib deinen Nachnamen ein
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error>
                    Bitte gib deine Email-Adresse ein
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Passwort" formControlName="password1" required>
                <mat-error>
                    Passwort muss mindestens {{passwordMinLength}} Zeichen haben
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Passwort wiederholen" formControlName="password2" required
                    [errorStateMatcher]="matcher">
                <mat-error *ngIf="registrationForm.hasError('notSame')">
                    Passwörter sind unterschiedlich
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary">Registrieren</button>
        </form>
    </mat-card-content>
</mat-card>