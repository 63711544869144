
export class Player {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    password?: string;
}

export class User extends Player {
    role: UserRole;
    token?: string;
}

export enum UserRole { Default = 1, Administrator = 2, Moderator = 4 }