import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ReservationType, Reservation } from '../_data/reservation';
import { Court } from '../_data/court';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationService extends ApiService {


  constructor(http: HttpClient) {
    super(http);
  }

  public getCourts() {
    return this.get<any>("courts");
  }

  public getPlayers() {
    return this.get<any>("players");
  }

  public getReservationsOfCourt(courtId: number) {
    return this.get<any>("courts/" + courtId + "/reservations");
  }

  public getReservationsOfPlayer(playerId: number) {
    return this.get<any>("players/" + playerId + "/reservations");
  }

  public makeReservation(courtId: number, reservationType: ReservationType, tournamentType: number,
    start: Date, end: Date, players: number[],
    additionalInfo?: string, ownerId?: number) {

    const reservation: Reservation = {
      start: start.toISOString(),
      end: end.toISOString(),
      players: players,
      courtId: courtId,
      ownerId: ownerId,
      description: additionalInfo,
      reservationType: reservationType,
      tournamentType: tournamentType
    }
    return this.makeReservationObj(reservation)
  }

  public makeReservationObj(reservation: Reservation) {
    return this.post<any, Reservation>("/reservations", reservation);
  }
  
  public deleteReservation(reservationId: number) {
    return this.delete<any>("/reservations/" + reservationId);
  }
}
