<mat-card class="courts">
    <mat-card-content>
        <mat-button-toggle-group #group="matButtonToggleGroup" [(value)]="selectedCourt">
            <mat-button-toggle [value]="court.id" *ngFor="let court of courts" (change)="selectionChanged($event)">
                {{court.name}}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content>
        <div class='app-calendar'>
            <full-calendar [options]="calendarOptions" #calenderComponent></full-calendar>
        </div>
    </mat-card-content>
</mat-card>