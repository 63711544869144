<mat-card>
    <mat-card-content>
        <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()">
            <h2>Platz reservieren</h2>
            <mat-error *ngIf="submitError">
                {{errorMessage}}
            </mat-error>
            <mat-form-field>
                <mat-label>Platz</mat-label>
                <mat-select name="court" formControlName="court" required (selectionChange)="onCourtChange()">
                    <mat-option *ngFor="let court of courts" [value]="court.id">
                        {{court.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Reservierungsart</mat-label>
                <mat-select name="reservationType" formControlName="reservationType" required (selectionChange)="onTypeChange()">
                    <mat-option *ngFor="let reservationType of reservationTypes" [value]="reservationType.value">
                        {{reservationType.displaytext}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Bewerb</mat-label>
                <mat-select name="reservationType" formControlName="tournamentType" required (selectionChange)="onTournamentChange()">
                    <mat-option *ngFor="let tournamentType of tournamentTypes" [value]="tournamentType.value">
                        {{tournamentType.displaytext}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-input">
                <mat-label>Datum auswählen</mat-label>
                <input matInput [matDatepicker]="picker" [min]="earliestPossibleReservationDate"
                    formControlName="reservationDate" required (dateInput)="onDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-input">
                <mat-label>Startzeit auswählen</mat-label>
                <mat-select name="startTime" formControlName="startTime" required>
                    <mat-option *ngFor="let starttime of starttimes" [value]="starttime.value">
                        {{starttime.displaytext}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width-input">
                <mat-label>Dauer auswählen</mat-label>
                <mat-select name="reservationDuration" formControlName="reservationDuration" required>
                    <mat-option *ngFor="let duration of durations" [value]="duration.value">
                        {{duration.displaytext}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="showPlayerOneAndTwo()">
                <mat-label>Spieler 1</mat-label>
                <mat-select name="player1" formControlName="player1">
                    <mat-option *ngFor="let player of players" [value]="player.id">
                        {{player.firstname}} {{player.lastname}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showPlayerOneAndTwo()">
                <mat-label>Spieler 2</mat-label>
                <mat-select name="player2" formControlName="player2">
                    <mat-option *ngFor="let player of players" [value]="player.id">
                        {{player.firstname}} {{player.lastname}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showPlayerThreeAndFour()">
                <mat-label>Spieler 3</mat-label>
                <mat-select name="player3" formControlName="player3">
                    <mat-option *ngFor="let player of players" [value]="player.id">
                        {{player.firstname}} {{player.lastname}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showPlayerThreeAndFour()">
                <mat-label>Spieler 4</mat-label>
                <mat-select name="player4" formControlName="player4">
                    <mat-option *ngFor="let player of players" [value]="player.id">
                        {{player.firstname}} {{player.lastname}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Eingegebener Wert nicht gültig!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <textarea matInput placeholder="Anmerkungen" formControlName="notes"></textarea>
                <mat-error>
                    Anmerkung beinhaltet ungültige Zeichen oder ist zu lange.
                </mat-error>
            </mat-form-field>

            <button mat-raised-button color="primary">Reservieren</button>
            <button mat-raised-button color="secondary" type="button" (click)="onCancelClick()">Zurück</button>
        </form>
    </mat-card-content>
</mat-card>