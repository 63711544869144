import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MyErrorStateMatcher } from '../_helpers/passwords-match-validator';
import { ApiErrorSummary } from '../_helpers/error.interceptor';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  loading = false;
  submitted = false;
  registrationError = false;
  matcher = new MyErrorStateMatcher();
  readonly passwordMinLength = 6;

  errorMessage = "";
  private genericErrorMessage = "Registrierung nicht erfolgreich!";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: UserService
  ) {
    this.errorMessage = this.genericErrorMessage;
    // redirect to home if already logged in
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.registrationForm = this.formBuilder.group(
      {
        firstname: ['', [Validators.required, Validators.minLength(3)]],
        lastname: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', Validators.email],
        password1: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
        password2: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]]
      },
      { validator: this.checkPasswords });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registrationForm.controls; }

  onSubmit() {
    if (this.loading) {
      return;
    }
    this.submitted = true;
    this.registrationError = false;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.register(this.f.email.value, this.f.password1.value, this.f.firstname.value, this.f.lastname.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(["/"]);
        },
        (error: ApiErrorSummary) => {
          switch (error.subcode) {
            case 101:
              this.errorMessage = "Email-Adresse bereits registriert!"
              break;
            case 102:
              this.errorMessage = "Passwort zu unsicher. Muss Zahlen, Groß- und Kleinbuchstaben beinhalten und mindestens 10 Zeichen lang sein."
              break;
            default:
              this.errorMessage = this.genericErrorMessage;
              break;
          }
          this.loading = false;
          this.registrationError = true;
        });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password1.value;
    let confirmPass = group.controls.password2.value;

    return pass === confirmPass ? null : { notSame: true }
  }
}