<mat-card>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <h2>Einloggen</h2>
            <mat-error *ngIf="loginError">
                Ungültige Email-Adresse oder Passwort!
            </mat-error>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Email" formControlName="username" required>
                <mat-error>
                    Bitte gib deine Email-Adresse ein
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Passwort" formControlName="password" required>
                <mat-error>
                    Bitte gib dein Passwort ein
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary">Login</button>
        </form>
    </mat-card-content>
</mat-card>