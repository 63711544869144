import { HttpClient } from '@angular/common/http';


export abstract class ApiService {

  private apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = "https://api.svkathal-tennis.at/" //"http://localhost/";
  }

  get<Response>(url: string) {
    return this.http.get<Response>(this.apiBaseUrl + url);
  }
  post<Response, Request>(url: string, data: Request) {
    return this.http.post<Response>(this.apiBaseUrl + url, data);
  }
  delete<Response>(url: string) {
    return this.http.delete<Response>(this.apiBaseUrl + url);
  }
}
