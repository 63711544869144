import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ReservationService } from '../_services/reservation.service';
import { Reservation, ReservationType } from '../_data/reservation';
import { Court } from '../_data/court';
import { Player } from '../_data/user';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog-component';

import 'moment/locale/de';
import { MatSnackBar } from '@angular/material/snack-bar';

moment.updateLocale('de', null);


export class ReservationViewPrepared {
  eventDay: string;
  eventTimeStart: string;
  eventTimeEnd: string;
  formattedPlayers: string[];
  reservationId: number;
  courtName: string;
  typePrettyPrint: string;
  typeIconName: string;
}

@Component({
  selector: 'app-my-reservations',
  templateUrl: './my-reservations.component.html',
  styleUrls: ['./my-reservations.component.less']
})
export class MyReservationsComponent implements OnInit {

  courts: Court[] = [];
  players: Player[] = [];
  originalReservations: Reservation[] = [];
  ownerId: number = null;
  reservations: ReservationViewPrepared[] = []

  constructor(
    private userService: UserService,
    private reservationService: ReservationService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.userService.currentUser.subscribe(x => {
      this.ownerId = x?.id;
      this.loadReservations();
    });

    reservationService.getCourts().subscribe((courts) => {
      this.courts = courts;
      this.prepareDataForView();
    });
    reservationService.getPlayers().subscribe((players) => {
      this.players = players;
      this.prepareDataForView();
    });
  }

  ngOnInit(): void {
  }

  onDelete(reservationId:number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog was closed, going to delete', reservationId);
        this.reservationService.deleteReservation(reservationId).subscribe(x => {
          this._snackBar.open("Reservierung storniert!", "Ok", {
            duration: 3000,
          });
          this.loadReservations();
        });
      }
    });
  }

  onEdit(evt, reservationId) {

  }

  private loadReservations() {
    this.reservationService.getReservationsOfPlayer(this.ownerId)
      .subscribe((reservations: Reservation[]) => {
        this.originalReservations = reservations;
        this.prepareDataForView();
      });
  }

  private prepareDataForView() {
    this.reservations = this.originalReservations
                              .sort(MyReservationsComponent.orderReservations)
                              .map(res => this.mapReservations(res));
  }

  private static orderReservations(a:Reservation, b:Reservation): number {
    if (a.start > b.start) {
      return 1;
    }
    if (a.start < b.start) {
      return -1;
    }
    return a.courtId - b.courtId;
  }

  private mapReservations(res:Reservation): ReservationViewPrepared {
    let startMoment = moment(res.start);
    let endMoment = moment(res.end);
    const day = startMoment.format("ddd DD.MM.YYYY")
    const timeStart = startMoment.format("HH:mm")
    const timeEnd = endMoment.format("HH:mm")

    let formattedPlayers = res.players.map(x => this.players.find(y => y.id == x)).map(x => x?.firstname + " " + x?.lastname);

    let typePrettyPrint = "";
    let typeIconName = "";
    switch (res.reservationType) {
      case ReservationType.Single:
        typePrettyPrint = "Einzel";
        typeIconName = "person";
        break;
      case ReservationType.Double:
        typePrettyPrint = "Doppel";
        typeIconName = "group";
        break;
      case ReservationType.Tournament:
        typePrettyPrint = "Meisterschaft";
        typeIconName = "poll";//bar_chart
        break;
      case ReservationType.Blocker:
        typePrettyPrint = "Platzsperre";
        typeIconName = "block";
        break;
    }

    let courtName = this.courts.find(x => x.id == res.courtId)?.name;

    return {
      eventDay: day,
      eventTimeStart: timeStart,
      eventTimeEnd: timeEnd,
      formattedPlayers: formattedPlayers,
      reservationId: res.id,
      typePrettyPrint: typePrettyPrint,
      typeIconName: typeIconName,
      courtName: courtName
    }
  }
}
