<mat-card [hidden]="reservations.length > 0">
    <mat-card-content>
        <span>Keine Reservierungen vorhanden.</span>
    </mat-card-content>
</mat-card>

<mat-card [hidden]="reservations.length == 0">
    <mat-card-content>
        <mat-selection-list #reservationsList [multiple]="false">
            <mat-list-item *ngFor="let reservation of reservations">
                <div class="list-row">
                    <!--<mat-icon matListAvatar>sports_tennis</mat-icon>-->
                    <div class="event-description-big-block">
                        <div matLine>
                            <button mat-fab><mat-icon matListAvatar>{{reservation.typeIconName}}</mat-icon></button>
                        </div>
                        <div class="courtname">
                            <div matLine class="">{{reservation.courtName}}</div>
                        </div>
                        <div class="event-description">
                            <div matLine class="">
                                <span class="type">{{reservation.typePrettyPrint}}:</span>
                                <span class="day">{{reservation.eventDay}}</span>
                                <span class="time">{{reservation.eventTimeStart}} - {{reservation.eventTimeEnd}}</span>
                            </div>
                            <div matLine>
                                <span>{{reservation.formattedPlayers.join(", ")}}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!-- <button mat-icon-button (click)="onEdit($event, reservation.id)">
                            <mat-icon>edit</mat-icon>
                        </button>-->
                        <button mat-icon-button (click)="onDelete(reservation.reservationId)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-list-item>
        </mat-selection-list>

    </mat-card-content>
</mat-card>