import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Platzbuchung';
  isAuthenticated: boolean;

  constructor(
    private router: Router,
    private authenticationService: UserService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.isAuthenticated = x != null);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
