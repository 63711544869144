import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReservationService } from '../_services/reservation.service';
import { first } from 'rxjs/operators';
import { Court } from '../_data/court';
import { Player, UserRole } from '../_data/user';
import { ReservationDuration, Reservation, ReservationType, ReservationTypeSelectEntry, TournamentType, TournamentTypeSelectEntry } from '../_data/reservation';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiErrorSummary } from '../_helpers/error.interceptor';
import * as moment from 'moment';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.less']
})
export class ReserveComponent implements OnInit {
  reservationForm: FormGroup;
  submitError = false;
  submitted = true;
  loading = false;
  errorMessage = "";
  private genericErrorMessage = "Reservierung konnte nicht durchgeführt werden!";

  ownerId: number = null;
  isAdmin: boolean = false;

  courts: Court[] = [];
  players: Player[] = [];
  durations: ReservationDuration[] = [];
  starttimes: ReservationDuration[] = [];
  reservationTypes: ReservationTypeSelectEntry[] = []
  tournamentTypes: TournamentTypeSelectEntry[] = []

  earliestPossibleReservationDate = new Date();

  private reservationsOfCourt: Reservation[] = [];
  private reservationsOfSelectedDay: Reservation[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private reservationService: ReservationService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.userService.currentUser.subscribe(x => {
      this.ownerId = x?.id;
      this.isAdmin = x?.role == UserRole.Administrator;
      this.buildReservationTypeOptions();
      this.buildDurationOptions();
    });

    reservationService.getCourts().subscribe((courts) => {
      this.courts = courts;
      this.reservationForm.controls.court.setValue(this.courts[0]?.id);
      this.onCourtChange();
    });
    reservationService.getPlayers().subscribe((players) => {
      this.players = players;
      this.reservationForm.controls.player1.setValue(this.ownerId);
    });
  }

  private buildStartingTimeOptions() {
    this.starttimes = [];

    const max_duration = this.getMaxEventDuration();
    const hasDate = this.f.reservationDate.value != null;
    const hasCourt = this.f.court.value != null;
    const hasReservationsOnDay = this.reservationsOfSelectedDay.length > 0;
    let ativities_index = 0;

    // Generate starting times
    const start_hour = 6;
    const last_hour = 21;
    const intervals = 30;
    for (let i = start_hour * 60; i <= last_hour * 60; i += intervals) {
      const hours = Math.floor(i / 60);
      const minutes = (i % 60);

      let maxReservationTimePossible = max_duration;
      if (hasReservationsOnDay && hasDate && hasCourt) {
        let startDate = this.f.reservationDate.value.clone().add(i, 'minutes') as moment.Moment;

        // fins first activity that starts after our desired time
        for (; ativities_index < this.reservationsOfSelectedDay.length; ativities_index++) {
          // already checked that in previous loop, there are no more events after that
          if (ativities_index == this.reservationsOfSelectedDay.length) {
            break;
          }

          let a = moment(this.reservationsOfSelectedDay[ativities_index].start);

          // is it in the future ? 
          var duration2 = moment.duration(a.diff(startDate)).asHours();
          if (duration2 > 0) {
            break;
          }
        }

        if (ativities_index == this.reservationsOfSelectedDay.length) {
          // there are no events after that, so max possible
          maxReservationTimePossible = max_duration;
        }
        else {
          let nextActivityMoment = moment(this.reservationsOfSelectedDay[ativities_index].start);

          var duration = moment.duration(nextActivityMoment.diff(startDate));
          maxReservationTimePossible = duration.asHours();
          if (maxReservationTimePossible > max_duration) {
            maxReservationTimePossible = max_duration
          }
        }


        // if we have an event that started earlier it might block us right now, so check that
        if (ativities_index > 0) {
          let prevActivityMoment = moment(this.reservationsOfSelectedDay[ativities_index - 1].end);
          let diff = moment.duration(prevActivityMoment.diff(startDate)).asHours();
          if (diff > 0) {
            maxReservationTimePossible = 0;
          }
        }
      }

      const maxText = (hasDate && hasCourt && hasReservationsOnDay) ? " Uhr (max " + maxReservationTimePossible + " Stunde(n))" : ""
      const displaytext =
        (hours < 10 ? "0" + hours : hours)
        + ":"
        + (minutes < 10 ? "0" + minutes : minutes)
        + maxText;
      this.starttimes.push({
        displaytext: displaytext,
        value: i
      });
    }
  }

  private getMaxEventDuration() {
    return 12; // good enough for now
    if (this.reservationForm?.controls?.reservationType == null) {
      return 4;
    }
    const extended_duration = this.f.reservationType.value == ReservationType.Tournament || this.f.reservationType.value == ReservationType.Blocker;
    return extended_duration ? 12 : 4;
  }

  private buildDurationOptions() {
    this.durations = [];
    const max_duration = this.getMaxEventDuration();
    for (let i = 30; i <= max_duration * 60; i += 30) {
      this.durations.push({
        displaytext: (i / 60) + " Stunden",
        value: i
      });
    }
  }


  private buildTournamentTypeOptions() {
    this.tournamentTypes = [
      { value: TournamentType.Training, displaytext: "Trainingspiel" },
      { value: TournamentType.Forderung, displaytext: "Forderungsspiel" },
      { value: TournamentType.Verein, displaytext: "Vereinsmeisterschaft" },
      { value: TournamentType.Kathal, displaytext: "Kathal-Cup" },
      { value: TournamentType.Zirben, displaytext: "Zirbenlandcup" },
      { value: TournamentType.Dorf, displaytext: "Dorfmeisterschaft" }
    ];
  }

  private buildReservationTypeOptions() {
    this.reservationTypes = [
      { value: ReservationType.Single, displaytext: "Einzel" },
      { value: ReservationType.Double, displaytext: "Doppel" }
    ];

    if (this.isAdmin) {
      this.reservationTypes.push({ value: ReservationType.Tournament, displaytext: "Meisterschaft" });
      this.reservationTypes.push({ value: ReservationType.Blocker, displaytext: "Platzsperre" });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.reservationForm.controls; }

  ngOnInit(): void {
    this.reservationForm = this.formBuilder.group({
      court: [null, Validators.required],
      reservationType: [ReservationType.Single, Validators.required],
      tournamentType: [0, Validators.required],
      reservationDate: [null, Validators.required],
      startTime: [14 * 60, Validators.required],
      reservationDuration: [120, Validators.required],
      player1: [{
        value: this.ownerId,
        disabled: false
      }],
      player2: [null, Validators.nullValidator],
      player3: [null, Validators.nullValidator],
      player4: [null, Validators.nullValidator],
      notes: ['', Validators.maxLength(255)],
    });

    this.buildReservationTypeOptions();
    this.buildTournamentTypeOptions();
    this.buildStartingTimeOptions();
    this.buildDurationOptions();
  }

  onSubmit() {
    if (this.loading) {
      return;
    }

    this.submitted = true;
    this.submitError = false;

    // stop here if form is invalid
    if (this.reservationForm.invalid) {
      return;
    }

    let eventDate = this.f.reservationDate.value;
    let start = this.f.startTime.value;
    let duration = this.f.reservationDuration.value;

    let startDate = eventDate.clone().add(start, 'minutes');
    let endDate = startDate.clone().add(duration, 'minutes');

    console.log(startDate)
    console.log(endDate)

    let players: number[] = []

    if (this.f.player1.value) {
      players.push(this.f.player1.value)
    }
    if (this.f.player2.value) {
      players.push(this.f.player2.value)
    }
    if (this.f.player3.value) {
      players.push(this.f.player3.value)
    }
    if (this.f.player4.value) {
      players.push(this.f.player4.value)
    }

    let reservation = new Reservation();
    reservation.courtId = this.f.court.value;
    reservation.ownerId = this.ownerId
    reservation.start = startDate;
    reservation.end = endDate;
    reservation.reservationType = this.f.reservationType.value;
    reservation.tournamentType = this.f.tournamentType.value;
    reservation.description = this.f.notes.value;
    reservation.players = players;

    this.loading = true;
    this.reservationService.makeReservationObj(reservation)
      .pipe(first())
      .subscribe(
        data => {
          console.log("booked");
          console.log(data);
          this._snackBar.open("Reservierung gespeichert!", "Ok", {
            duration: 3000,
          });
          this.router.navigateByUrl('/');
        },
        (error: ApiErrorSummary) => {
          switch (error.subcode) {
            case 203:
              this.errorMessage = "Ungültiges Start-Datum!"
              break;
            case 204:
              this.errorMessage = "Ungültiges End-Datum!"
              break;
            case 205:
              this.errorMessage = "Mindestdauer für Reservierungen unterschritten."
              break;
            case 206:
              this.errorMessage = "Maximaldauer für Reservierungen überschritten."
              break;
            case 207:
              this.errorMessage = "Reservierung liegt in der Vergangenheit."
              break;
            case 208:
            case 209:
              this.errorMessage = "Angegebene Spieler nicht gültig."
              break;
            case 210:
              this.errorMessage = "Nicht genügend Spieler für ausgewählten Reservierungstyp angegeben."
              break;
            case 211:
              this.errorMessage = "Mindestens ein Spieler wurde mehrfach ausgewählt."
              break;
            case 212:
              this.errorMessage = "Reservierung kollidiert mit einer anderen Reservierung."
              break;
            default:
              this.errorMessage = this.genericErrorMessage;
              break;
          }

          this.loading = false;
          this.submitError = true;
        });
  }

  onCancelClick() {
    this.router.navigateByUrl('/');
  }

  showPlayerOneAndTwo() {
    const val = this.f.reservationType.value;
    return val !== ReservationType.Blocker
  }
  showPlayerThreeAndFour() {
    const val = this.f.reservationType.value;
    return val === ReservationType.Double
  }

  onCourtChange() {
    console.log("court changed");
    let courtId = this.f.court.value;
    this.reservationService
      .getReservationsOfCourt(courtId)
      .subscribe(reservations => {
        this.reservationsOfCourt = reservations;
        this.onDateChange();
      });
  }

  onTypeChange() {
    console.log("type changed");
    this.buildStartingTimeOptions();
    this.buildDurationOptions();
  }

  onDateChange() {
    console.log("date changed");
    let date = this.f.reservationDate.value;
    if (!date) {
      return;
    }
    let date_str = date.format("yyyy-MM-DD");
    this.reservationsOfSelectedDay = this.reservationsOfCourt
      .filter(x => {
        let parsed_date = x.start.substr(0, 10);
        return parsed_date == date_str;
      })
      .sort((x, y) => new Date(x.start).getTime() - new Date(y.start).getTime());
    this.buildStartingTimeOptions();
  }
  onTournamentChange() {
    console.log("tournament change");
  }
}
