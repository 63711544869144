<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <mat-nav-list>
      <a mat-list-item *ngIf="!isAuthenticated" routerLink="/login">
        <mat-icon class="icon">login</mat-icon>
        <span class="label">Login</span>
      </a>
      <a mat-list-item *ngIf="!isAuthenticated" routerLink="/registrierung">
        <mat-icon class="icon">person_add</mat-icon>
        <span class="label">Registrieren</span>
      </a>
      <a mat-list-item *ngIf="isAuthenticated" routerLink="/">
        <mat-icon class="icon">home</mat-icon>
        <span class="label">Home</span>
      </a>
      <a mat-list-item *ngIf="isAuthenticated" routerLink="/reservierung">
        <mat-icon class="icon">group_add</mat-icon>
        <span class="label">Neue Reservierung</span>
      </a>
      <a mat-list-item *ngIf="isAuthenticated" routerLink="/meine-reservierungen">
        <mat-icon class="icon">sports_tennis</mat-icon>
        <span class="label">Meine Reservierungen</span>
      </a>
      <a mat-list-item type="button" (click)="logout()" *ngIf="isAuthenticated">
        <mat-icon class="icon">input</mat-icon>
        <span class="label">LogOut</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <div fxHide.gt-xs>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div>
        <a routerLink="/" class="brand-logo">
          <img src="assets/kathal_logo.jpg"/>
        </a>
      </div>
      <div class="appname">
        <a routerLink="/">
          Platzreservierung
        </a>
      </div>
      <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
        <ul fxLayout fxLayoutGap="20px" class="navigation-items">
          <li>
            <a *ngIf="!isAuthenticated" routerLink="/login">
              <mat-icon class="icon">login</mat-icon>
              <span class="label">Login</span>
            </a>
          </li>
          <li>
            <a *ngIf="!isAuthenticated" routerLink="/registrierung">
              <mat-icon class="icon">person_add</mat-icon>
              <span class="label">Registrierung</span>
            </a>
          </li>
          <li>
            <a *ngIf="isAuthenticated" routerLink="/">
              <mat-icon class="icon">home</mat-icon>
              <span class="label">Home</span>
            </a>
          </li>
          <li>
            <a *ngIf="isAuthenticated" routerLink="/reservierung">
              <mat-icon class="icon">group_add</mat-icon>
              <span class="label">Neue Reservierung</span>
            </a>
          </li>
          <li>
            <a *ngIf="isAuthenticated" routerLink="/meine-reservierungen">
              <mat-icon class="icon">sports_tennis</mat-icon>
              <span class="label">Meine Reservierungen</span>
            </a>
          </li>
          <li>
            <a (click)="logout()" *ngIf="isAuthenticated">
              <mat-icon class="icon">input</mat-icon>
              <span class="label">LogOut</span>
            </a>
          </li>
        </ul>
      </div>
    </mat-toolbar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>