
export class Reservation {
    id?: number;
    start: string;
    end: string;

    reservationType: ReservationType;
    tournamentType: number;

    ownerId: number;
    players: number[];
    courtId: number;

    description: string;
}

export enum ReservationType { Single = 1, Double = 2, Tournament = 3, Blocker = 4 }
export enum TournamentType { Training = 0, Forderung = 1, Verein = 2, Kathal = 3, Zirben = 4, Dorf = 5 }


export class SelectOptionEntry<T> {
    value: T;
    displaytext: string;
}

export class ReservationDuration extends SelectOptionEntry<number> { }
export class StartTimeSelectEntry extends SelectOptionEntry<number> { }
export class ReservationTypeSelectEntry extends SelectOptionEntry<ReservationType> { }
export class TournamentTypeSelectEntry extends SelectOptionEntry<TournamentType> { }