import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../_services/user.service';

export class ApiErrorSummary {
    httpstatuscode: number;
    httpstatustext: string;
    subcode: number;
    message: string;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }

            const apierror: ApiErrorSummary = {
                httpstatuscode: err.status,
                httpstatustext: err.statusText,
                subcode: err.error.subcode,
                message: err.error.message
            };

            return throwError(apierror);
        }))
    }
}