import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthenticatedOnlyGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ReserveComponent } from './reserve/reserve.component';
import { MyReservationsComponent } from './my-reservations/my-reservations.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthenticatedOnlyGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'registrierung', component: RegisterComponent },
  { path: 'reservierung', component: ReserveComponent },
  { path: 'meine-reservierungen', component: MyReservationsComponent },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
