import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '../_data/user'

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  private storageKeyUser = "currentUser";
  private autologon_after_register = true;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(http: HttpClient) {
    super(http);
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(this.storageKeyUser)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public isAuthenticated() {
    return this.currentUserSubject.value != null;
  }

  public login(logon: string, password: string) {
    return this.post<User, any>("/users/authenticate", { logon, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.storageKeyUser, JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  public register(
    logon: string, password: string,
    firstname: string, lastname: string) {
    let subj = this.post<User, any>("/users/register", { logon, password, firstname, lastname });

    if (this.autologon_after_register) {
      return subj.pipe(mergeMap(reg => this.login(logon, password)));
    } else {
      return subj;
    }
  }

  public logout() {
    localStorage.removeItem(this.storageKeyUser);
    this.currentUserSubject.next(null);
  }

}
