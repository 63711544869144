import { Component, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Router } from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { UserService } from '../_services/user.service';
import { ReservationService } from '../_services/reservation.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import deLocale from '@fullcalendar/core/locales/de';
import { Reservation, ReservationType } from '../_data/reservation';
import { Player } from '../_data/user';
import { Court } from '../_data/court';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  calendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin],
    allDaySlot: false,
    slotMinTime: "06:00:00",
    slotMaxTime: "22:00:00",
    locale: deLocale,
    weekends: true,
    firstDay: 1,
    
    initialView: 'timeGridThreeDay',

    views: {
      timeGridThreeDay: {
        type: 'timeGrid',
        duration: { days: 3 },
        buttonText: '3 Tage'
      },
      timeGridFiveDay: {
        type: 'timeGrid',
        duration: { days: 5 },
        buttonText: '5 Tage'
      }
    },
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'today,prev,next'
    },
    footerToolbar: {
      left: '',
      center: 'timeGridDay,timeGridThreeDay,timeGridFiveDay,timeGridWeek',
      right: ''
    },

    businessHours: {
      // days of week. an array of zero-based day of week integers (0=Sunday)
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:00',
      endTime: '20:00'
    },
    events: (info, successCb, errorCb) => this.loadCalendarEvents(info, successCb, errorCb)
  };

  // references the #calenderComponent in the template
  @ViewChild('calenderComponent') calendarComponent: FullCalendarComponent;

  private eventsAvailable: Reservation[] = [];
  private availablePlayers: Player[] = [];
  courts: Court[] = [];
  selectedCourt: number;
  private ownerId: number = null;

  private loadCalendarEvents(info, successCallback, failureCallback) {
    //start: info.start.valueOf(),
    //end: info.end.valueOf()
    let availablePlayers = this.availablePlayers;

    let eventEntries = this.eventsAvailable.map(evt => eventMapper(evt));
    successCallback(eventEntries);

    function eventMapper(evt: Reservation) {
      function colorMapping(type, tournamentType) {
        let color = '';
        switch (type) {
          default:
          case ReservationType.Single:
            color = '#28a745';
            break;
          case ReservationType.Double:
            color = '#3788d8';
            break;
          case ReservationType.Tournament:
            color = '#ffc107';
            break;
          case ReservationType.Blocker:
            color = '#D9230F';
            break;
        }
        
        switch (tournamentType) {
          default:
          case 0:
            color = '#3ca36f';
            break;
          case 1:
            color = '#41bf9b';
            break;
          case 2:
            color = '#f4b255';
            break;
          case 3:
            color = '#6a6384';
            break;
          case 4:
            color = '#c4b8ca';
            break;
          case 5:
            color = '#a172bc';
            break;
        }
        return color;
      }

      function eventName(evt) {
        //let owner = this.availablePlayers.filter(x => x.id == evt.ownerId);
        let players: Player[] = evt.players.map(x => availablePlayers.filter(y => y.id == x)[0]).map(player => player.firstname + " " + player.lastname);
        let name = ''
        switch (evt.reservationType) {
          default:
          case ReservationType.Single:
            name = 'Einzel: ' + players.join(", ");
            break;
          case ReservationType.Double:
            name = 'Doppel: ' + players.join(", ");
            break;
          case ReservationType.Tournament:
            name = 'Meisterschaft';
            break;
          case ReservationType.Blocker:
            name = 'Platzsperre';
            break;
        }
        
        // FixMe: Make that nice
        let tournament_name = ""
        switch (evt.tournamentType) {
          default:
          case 0:
            tournament_name = "Trainingsspiel";
            break;
          case 1:
            tournament_name = "Forderungsspiel";
            break;
          case 2:
            tournament_name = "Vereinsmeisterschaft";
            break;
          case 3:
            tournament_name = "Kathal-Cup";
            break;
          case 4:
            tournament_name = "Zirbenlandcup";
            break;
          case 5:
            tournament_name = "Dorfmeisterschaft";
            break;
        }
        return name + " " + evt.description + " (" + tournament_name + ")";
      }


      return {
        title: eventName(evt),
        start: evt.start,
        end: evt.end,
        backgroundColor: colorMapping(evt.reservationType, evt.tournamentType)
      };
    }
  }

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private reservationService: ReservationService
  ) {
    this.userService.currentUser.subscribe(x => this.ownerId = x?.id);
    this.reservationService.getPlayers().subscribe(players => this.availablePlayers = players);
    this.reservationService.getCourts().subscribe(courts => {
      this.courts = courts;
      if (courts.length > 0) {
        this.selectedCourt = courts[0].id
      }
      this.fetchCalendarEvents();
    });
    setTimeout(() => this.adjustCalendarViewToScreen(), 500);
  }

  ngOnInit(): void {
  }

  private adjustCalendarViewToScreen() {
    if (this.calendarComponent == undefined) {
      console.error("Init not yet completed");
      return;
    }
    const isExtraSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 959px)');
    const isMediumScreen = this.breakpointObserver.isMatched('(max-width: 1279px)');
    if (isExtraSmallScreen) {
      this.calendarComponent.getApi().changeView('timeGridThreeDay');
    }
    else if (isMediumScreen) {
      this.calendarComponent.getApi().changeView('timeGridFiveDay');
    }
    else {
      this.calendarComponent.getApi().changeView('timeGridWeek');
    }
  }

  private fetchCalendarEvents() {
    this.reservationService.getReservationsOfCourt(this.selectedCourt).subscribe(x => {
      console.log(x)
      this.eventsAvailable = x;
      this.calendarComponent.getApi().refetchEvents();
    });
  }

  selectionChanged(item) {
    console.log("Selected court changed: " + item.value);
    this.fetchCalendarEvents();
  }

  public btnNewReservationClick() {
    this.router.navigateByUrl('/reservierung');
  }

  public btnMyReservationsClick() {
    this.router.navigateByUrl('/meine-reservierungen');
  }

}
